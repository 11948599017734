import React from 'react';
import clsx from 'clsx';
import {SanityImage as SanityImageType} from '~/domains/sanity';
import SanityImage from 'components/SanityImage';

export default function LoadingScreen({logo}: {logo: SanityImageType}) {
  return (
    <header
      className={clsx(
        'align-center fixed top-0 z-[1000] bg-white flex h-[100svh] w-full justify-between px-8',
      )}
      role="banner"
    >
      <div
        className={clsx(
          'absolute bottom-0 top-0 left-1/2 flex w-[200px] -translate-x-1/2 items-center',
          // 'lg:w-[65px]',
        )}
      >
        <div className="w-[200px] animate-pulse">
          <SanityImage
            layout="responsive"
            sizes={['50vw, 100vw']}
            src={logo?.asset._ref}
          />
        </div>
      </div>
    </header>
  );
}
